<template>
    <div>
        <v-data-table
            v-model="valor"
            class="pa-0 ma-0"
            multi-sort
            :item-key="itemKey"
            :headers="headers"
            :items="items"
            :search="search"
            :sort-by="sortBy"
            :show-group-by="showGroupby"
            :dense="dense"
            :hide-default-header="hideDefaultHeader"
            :hide-default-footer="hideDefaultFooter"
            :show-select="multiple"
            :class="[seleccion && !multiple ? 'row-pointer' : '']"
            :disable-pagination="disablePagination"
            @item-selected="seleccionarIndividual">

            <template v-for="(col, index) in formattedHeaders" v-slot:[`item.${col.value}`]="{ item }">                
                <div v-if="col.value == 'opciones'" :key="index" class="d-flex ma-0 pa-0">
                    <Button tipo="modificar" size="x-small" v-if="permiteModificar" @click="actualizar(item.id)"/> 
                    <Button tipo="ver" size="x-small" v-if="!permiteModificar && permiteVisualizar" @click="actualizar(item.id)"/>
                    <Button tipo="descargar" size="x-small" v-if="permiteDescargar && item.direcciondocumentostorage" @click="abrirDocumento(item.direcciondocumentostorage)"/>  
                    <Button tipo="imprimir" size="x-small" v-if="permiteImprimir" @click="imprimir(item.id)"/>
                    <Button tipo="eliminar" size="x-small" v-if="!seleccion && permiteEliminar" @click="eliminar(item.id)"/>
                </div>
                <div v-else-if="col.value == 'modificar' && permiteModificar" :key="index" class="ma-0 pa-0">
                    <Button tipo="modificar" size="x-small" @click="actualizar(item.id)"/>                    
                </div>
                <div v-else-if="col.value == 'eliminar' && permiteEliminar" :key="index" class="ma-0 pa-0">
                    <Button tipo="eliminar" size="x-small" @click="eliminar(items.indexOf(item))"/>                    
                </div>
                <!-- <div v-else-if="col.tipo == 'boolean'" :key="index">
                    <v-simple-checkbox v-model="item[col.value]" disabled></v-simple-checkbox>
                </div> -->
                <div v-else-if="col.tipo == 'boolean'" :key="index" @click="seleccionarIndividual(item.id)">
                    <div v-if="item[col.value]">
                        <v-icon>check_circle_outline</v-icon>
                    </div>
                    <div v-else>
                        <v-icon>highlight_off</v-icon>
                    </div>
                </div>
                <div v-else-if="col.tipo == 'moneda'" :key="index" @click="seleccionarIndividual(item.id)">
                    {{item[col.value] | decimales | moneda}}
                </div>
                <div v-else-if="col.tipo == 'porcentaje'" :key="index" @click="seleccionarIndividual(item.id)">
                    {{item[col.value] | decimales | porcentaje}}
                </div>
                <div v-else-if="col.tipo == 'fecha'" :key="index" @click="seleccionarIndividual(item.id)">
                    {{item[col.value] | fecha}}
                </div>
                <div v-else-if="col.tipo == 'fechahora'" :key="index" @click="seleccionarIndividual(item.id)">
                    {{item[col.value] | fechahora}}
                </div>
                <div v-else-if="col.tipo == 'fechahoraminuto'" :key="index" @click="seleccionarIndividual(item.id)">
                    {{item[col.value] | fechahoraminuto}}
                </div>
                <div v-else-if="col.tipo == 'email'" :key="index" @click="seleccionarIndividual(item.id)">
                    <div v-if="col.editable">
                        <Input v-model="item[col.value]"
                            label="Email"
                            tipo="email"
                            class="ma-0 pa-0 pt-1"
                            :dense="dense"/>
                    </div>
                    <div v-else>
                        {{item[col.value]}} 
                    </div>
                </div>
                <div v-else-if="col.tipo == 'numero'" :key="index" @click="seleccionarIndividual(item.id)">
                    <div v-if="col.editable">
                      <v-text-field v-model="item[col.value]"
                            class="ma-0 pa-0"
                            hide-details
                            type="number"
                            :dense="dense"
                            @change="$emit(col.value,items.indexOf(item))"/>
                    </div>
                    <div v-else>
                        {{item[col.value] | decimales}} 
                    </div>
                </div>
                <div v-else-if="col.tipo == 'selectpuntosventa'" :key="index" @click="seleccionarIndividual(item.id)">
                    <SelectPuntosVenta v-model="item[col.value]" obligatorio hidedetails dense titulo=""/>
                </div>
                <div v-else :key="index" @click="seleccionarIndividual(item.id)">
                    <div v-if="col.editable">
                        <v-text-field
                            v-model="item[col.value]"
                            class="ma-0 pa-0 pt-1"
                            :dense="dense"
                        />
                    </div>
                    <div v-else>
                        {{item[col.value]}} 
                    </div>
                </div>

            </template>
        </v-data-table>
        <Confirm ref="confirm"></Confirm>
    </div>
</template>

<script>

    import Input from '@/components/input/Input';
    import Button from './button/Button.vue';
    import SelectPuntosVenta from '../select/SelectPuntosVenta.vue';

    export default {
        
        components: {
            Input,
            Button,
            SelectPuntosVenta
        },

        props: {
            value: {type: Array,
                default(){return[]}},
            headers: {type: Array},
            items: {type: Array,
                default(){return[]}},
            search: {type: String},
            sortBy: {type: String},
            showGroupby: {type: Boolean,
                default: true},
            dense: {type: Boolean,
                default: false},
            hideDefaultHeader: {type: Boolean,
                default: false},  
            hideDefaultFooter: {type: Boolean,
                default: false},
            seleccion: {type: Boolean,
                default: false},
            multiple: {type: Boolean,
                default: false},          
            permiteModificar: {type: Boolean,
                default: true},
            permiteEliminar: {type: Boolean,
                default: true},
            permiteVisualizar: {type: Boolean,
                default: true},
            permiteDescargar: {type: Boolean,
                default: false},
            permiteImprimir: {type: Boolean,
                default: false},
            disablePagination: {type: Boolean,
                default: false},
            itemKey: {type: String,
                default: 'id'},
        },

        computed: {
            valor: {
                get() {
                    return this.value;
                },
                set(value) {    
                    this.$emit("input",value);
                }
            },

            formattedHeaders() {
                return this.headers.map(header => {
                    // Verificar si el valor es un objeto
                    if (header.value.includes('.')) {
                        const keys = header.value.split('.');
                        const obj = keys.reduce((acc, key) => {
                        return acc ? acc[key] : undefined;
                        }, this.items[0]); // Utilizamos el primer elemento de la lista para obtener el objeto anidado
                        return { ...header, value: obj };
                    } else {
                        return header;
                    }
                });
            },
        },

        methods: {

            actualizar(id) {
                this.$emit("actualizar",id);
            },

            eliminar(val) {                
                this.$refs.confirm.open('Atención', '¿Confirma la eliminación?', {tipo:'confirmacion', color: 'red'})
                .then((confirm) => {
                    if (confirm){
                        this.$emit("eliminar",val)
                    }
                })
            },
            
            seleccionarIndividual(id){
                if (this.multiple){
                    //ACA SE DEBERIA HACER QUE SE SELECCIONE EL CHECK                    
                }else{
                    const item = this.items.find(item => item.id === id);
                    this.$emit('seleccionar',item);    
                }
            },

            abrirDocumento(link){
                if (link){
                    const fileLink = document.createElement('a');
                    fileLink.href = link;
                    fileLink.setAttribute('target','_blank');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            },

            imprimir(id) {
                this.$emit("imprimir",id);
            },
        }

    }
</script>

<style lang="css" scoped>
    .row-pointer >>> tbody tr :hover {
        cursor: pointer;
    }
    .v-data-table >>> .v-data-table__wrapper > table > tbody > tr > td,
    .v-data-table >>> .v-data-table__wrapper > table > thead > tr > th{
        padding: 4px !important;
    }
</style>