<template>
    <FormEdicion
        v-model="show"
        titulo="Turnos"
        maxWidth="40%"
        :endPointGuardar="endPointGuardar"
        :tabla="tabla"
        :camposPorDefecto="camposPorDefecto"
        @actualizoFormEdicion="actualizoFormEdicion">

        <template v-slot:Campos>
            <SelectSectoresTramites :sectoresSeleccionados.sync="tabla.idsector" 
                :tramitesSeleccionados.sync="tabla.idtramite" 
                obligatorioSectores
                :showTramite="false"/>

            <SelectDias v-model="parametros.dias" multiple/>

            <div class="d-flex">
                <v-col class="pa-0 pr-1">  
                    <InputFechaRango v-model="parametros.rangofecha" obligatorio/>
                </v-col>
                <v-col class="pa-0"> 
                    <SelectEstadosTurno v-model="idestado" obligatorio noSolicitaPersona/>
                </v-col>
            </div>    

            <div class="d-flex">
                <SwitchCustom v-model="parametros.definehorario" label="Definir horario" class="ma-0"/>  
                <v-spacer/>
                <SwitchCustom v-model="parametros.feriados" label="Incluir feriados" class="ma-0"/>   
            </div>

            <div class="d-flex" v-show="parametros.definehorario">
                <v-col class="pa-0 pr-1">  
                    <InputHoraRango :desde.sync="parametros.horadesde" :hasta.sync="parametros.horahasta" obligatorio/>
                </v-col>
                <v-col class="pa-0 pr-1">  
                    <InputHora v-model="tabla.intervalo" obligatorio/>
                </v-col>
                <v-col class="pa-0">
                    <Input v-model="parametros.cantidad" 
                        :label="labelCantidad" 
                        tipo="number" 
                        min="1" 
                        obligatorio
                        :permite-valor-cero="false"/>
                </v-col>
            </div>
        </template>  

        <template v-slot:Otros> 
            <Tab v-model="tab" :tabs="tabs" v-show="cantidadTurnos != 0 || cantidadEventos != 0">
                <template v-slot:turnos>
                    <v-card>
                        <v-card-text class="px-0 py-2">
                            <DataTable 
                                :items="tabla.detalleturnos"
                                :headers="headersTurnos"
                                hideDefaultHeader
                                dense
                                @eliminar="eliminar"
                            />
                        </v-card-text>
                    </v-card>
                </template>
                <template v-slot:eventos>
                    <v-card>
                        <v-card-text class="px-0 py-2">
                            <DataTable 
                                :items="eventos"
                                :headers="headersEventos"
                                hideDefaultHeader
                                dense
                            />
                        </v-card-text>
                    </v-card>
                </template>
            </Tab>
        </template>    
    </FormEdicion>

</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {turnosApi} from '../../api/TurnosApi';
    import {feriadosApi} from '../../api/FeriadosApi';
    import {calendariosApi} from '../../api/CalendariosApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue'
    import SelectSectores from '@/components/select/SelectSectores.vue';
    import SelectDias from '@/components/select/SelectDias.vue'
    import InputFechaRango from '@/components/input/InputFechaRango.vue'
    import InputHoraRango from '@/components/input/InputHoraRango.vue'
    import InputHora from '@/components/input/InputHora.vue'
    import DataTable from '@/components/base/DataTable.vue';
    import dayjs from "dayjs"
    import SelectEstadosTurno from '../../components/select/SelectEstadosTurno.vue';
    import SelectSectoresTramites from '../../components/select/SelectSectoresTramites.vue';
    import Tab from '../../components/base/Tab.vue';
    import SwitchCustom from '../../components/base/Switch.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SelectSectores,
            SelectDias,
            InputFechaRango,
            InputHoraRango,
            InputHora,
            DataTable,
            SelectEstadosTurno,
            SelectSectoresTramites,
            Tab,
            SwitchCustom,
        },
        
        data: () => ({
            endPointGuardar: turnosApi.agregar,
            headersTurnos:[{value: "eliminar", sortable: true, filterable: false, groupable: false},
                {value: "dia", sortable: true, filterable: false, groupable: false},
                {value: "fecha", sortable: true, filterable: false, groupable: false},
                {value: "hora", sortable: true, filterable: false, groupable: false}],
            headersEventos: [{value: "dia", sortable: true, filterable: false, groupable: false},
                {value: "fecha", sortable: true, filterable: false, groupable: false},
                {value: "calendario", sortable: true, filterable: false, groupable: false},
                {value: "detalle", sortable: true, filterable: false, groupable: false}],
            parametros:{
                dias: [],
                rangofecha: [],
                horadesde: '',
                horahasta: '',
                cantidad: 1,
                definehorario: true,
            },
            tabla:{
                idsector: 0, 
                idtramite: null, 
                intervalo: '',
                detalleturnos: [],
                feriados: false,
            },
            camposPorDefecto:{
                idsector: 0,
            },
            reset: false,
            idestado: 0,
            eventos: [],
            tab: 0,
            tabs: [
                {nombre: 'turnos', habilitado: true, cantidad: 0},
                {nombre: 'eventos', habilitado: true, cantidad: 0}
            ],
            cantidadTurnos: 0,
            cantidadEventos: 0,
        }),

        computed: {
            labelCantidad(){
                return this.parametros.definehorario ? "Turnos por hora" : "Turnos por Día"
            },      
        },

        watch:{
            show(val){
                if (val){
                    this.camposPorDefecto.idsector = this.$store.state.sectoresModule.idsSectores[0]
                }
            },
            parametros: {
                deep: true,
                handler(){
                    this.calcularTurnos()
                }
            },
            'parametros.rangofecha'(){
                this.buscarEventos()
            },
            'tabla.intervalo'(){
                this.calcularTurnos()
            },
            idestado() {
                this.tabla.detalleturnos.forEach((elemento,indice,array) => {
                    elemento.idestado = this.idestado
                })
            },
            'tabla.detalleturnos.length'(val){
                this.cantidadTurnos = val
                this.tabs[0].cantidad = val
            },
            'eventos.length'(val){
                this.cantidadEventos = val
                this.tabs[1].cantidad = val
            }
        },

        methods: {

            async calcularTurnos(){
                
                let horaDesde
                let horaHasta
                let intervalo

                this.tabla.detalleturnos = []

                if(!this.parametros.rangofecha || this.parametros.rangofecha.length < 2) return

                if(this.parametros.definehorario){ 
                    if(!this.parametros.horadesde ||  
                       !this.parametros.horahasta || !this.tabla.intervalo) return
                
                    var parts = this.tabla.intervalo.split(':');
                    if (parts[0] == '00' && parts[1] == '00') return  

                    horaDesde = this.$convertirStringHora(this.parametros.horadesde)
                    horaHasta = this.$convertirStringHora(this.parametros.horahasta)
                    intervalo = this.$convertirStringHora(this.tabla.intervalo)
                }else{
                    this.tabla.intervalo = ''
                }

                const fechas = Array.from(this.parametros.rangofecha).sort()
                const dias = Array.from(this.parametros.dias)
                const fechaDesde = this.$convertirStringDate(fechas[0])
                const fechaHasta = this.$convertirStringDate(fechas[1])

                let feriados = []
                if(!this.parametros.feriados){
                    const response = await feriadosApi.buscarVarios(this.parametros.rangofecha)
                    feriados = response.data.map(a => dayjs(a.fecha).format('YYYY-MM-DD'))
                }

                for (let fecha = fechaDesde; fecha <= fechaHasta; fecha.setDate(fecha.getDate() + 1)) {
                    if ((dias.includes(fecha.getDay()) || dias.length === 0) && (!feriados.includes(dayjs(fecha).format("YYYY-MM-DD")) || feriados.length === 0)){
                        
                        if (this.parametros.definehorario){
                            let fechaHoraDesde = this.$seteaHorasMinutos(fecha,horaDesde)
                            let fechaHoraHasta = this.$seteaHorasMinutos(fecha,horaHasta)

                            for (let fechaHora = fechaHoraDesde;
                                fechaHora <= fechaHoraHasta;
                                fechaHora.setHours(fechaHora.getHours() + intervalo.getHours()),
                                fechaHora.setMinutes(fechaHora.getMinutes() + intervalo.getMinutes())) {
                                
                                for(let cantidad = 1; cantidad <= this.parametros.cantidad; cantidad++){
                                    this.tabla.detalleturnos.push({
                                        dia:this.$obtenerDia(fechaHora), 
                                        fecha:this.$convertirObjectDateString(fechaHora,'dma'),
                                        hora:this.$obtenerHora(fechaHora,'hml'),
                                        fechahora:this.$convertirObjectDateString(fechaHora,'amd','-') + ' ' + this.$obtenerHora(fechaHora,'hms'),
                                        idestado:this.idestado}
                                    )
                                }
                            }
                        }else{
                            for(let cantidad = 1; cantidad <= this.parametros.cantidad; cantidad++){
                                this.tabla.detalleturnos.push({
                                    dia:this.$obtenerDia(fecha), 
                                    fecha:this.$convertirObjectDateString(fecha,'dma'),
                                    fechahora:this.$convertirObjectDateString(fecha,'amd','-') + ' ' + this.$obtenerHora(fecha,'hms'),
                                    idestado:this.idestado}
                                )
                            }
                        }
                    }
                }
                if (this.tabla.detalleturnos.length != 0){
                    this.tab = 0
                } 

            },

            async buscarEventos(){
                this.eventos = []
                if (!this.parametros.rangofecha || this.parametros.rangofecha.length != 2) return
                if (this.tabla.idsector == 0) return
                const filtro={
                    idsSectores: Array.from(this.tabla.idsector.toString()).map(Number),
                    rangoFecha: this.parametros.rangofecha,
                }
                const response = await calendariosApi.buscarVariosDetalle(filtro)
                const listaEventos = response.data
                if (listaEventos.length > 0){
                    const fechas = Array.from(this.parametros.rangofecha).sort()
                    const fechaDesde = this.$convertirStringDate(fechas[0])
                    const fechaHasta = this.$convertirStringDate(fechas[1])
                    
                    for (let fecha = fechaDesde; fecha <= fechaHasta; fecha.setDate(fecha.getDate() + 1)) {
                        for (let i=0; i < listaEventos.length; i++){
                            const fechaInicioEvento = this.$convertirStringDate(listaEventos[i].fechainicio.substr(0,10))
                            const fechaFinEvento = this.$convertirStringDate(listaEventos[i].fechafin.substr(0,10))
                            if(fecha >= fechaInicioEvento && fecha <= fechaFinEvento){
                              this.eventos.push({
                                dia:this.$obtenerDia(fecha), 
                                fecha:this.$convertirObjectDateString(fecha,'dma'),
                                calendario:listaEventos[i].calendario.nombre,
                                detalle:listaEventos[i].detalle,
                              })
                            }
                        }
                    }
                    if (this.tabla.detalleturnos.length == 0){
                        this.tab = 1
                    } 
                }    
            },
            
            eliminar(index){
                this.tabla.detalleturnos.splice(index,1);
            },

        }
    }
</script>